import {useEffect,useState} from 'react';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Reply from './reply';
import ViewReply from './viewReply';

const ShowCommentItem = (props) => {
    const[isReplyVisible,setIsReplyVisible] = useState(false);
    const[isViewReplyVisible,setIsViewReplyVisible] = useState(false);
    //for refreshing reply and view reply components
    const [refresh, setRefresh] = useState(false);

    return ( 
        <div className="flex flex-row gap-20 mt-20">
             <div className='flex flex-col w-20 gap-5'>
                <AccountCircleIcon className=' self-center' sx={{fontSize:'65px'}} />
                {/* Place prop with username here */}
                <span className="  self-center font-extralight" >{props.username}</span>
            </div>
            <div className='flex flex-col gap-4'>
                <p className="p-5 font-extralight text-lg">
                    {props.comment}
                </p>
                
                <div>
                    <div className="grid grid-cols-1 md:grid-cols-3 ml-5">
                        <span className=' font-thin hover:text-orange-400 cursor-pointer'>{props.date_commented}</span>

                        {/* <div className='grid grid-cols-1 md:grid-cols-2 '> */}
                            <span 
                                className=' font-thin hover:text-orange-400 cursor-pointer'
                                onClick={()=>setIsReplyVisible(!isReplyVisible)}
                                >
                                reply
                            </span>

                            <span
                                className='font-thin hover:text-orange-400 cursor-pointer'
                                onClick={()=>{
                                    setIsViewReplyVisible(!isViewReplyVisible)
                                    // setIsReplyVisible(isReplyVisible)
                                }}
                                >
                                View replies
                            </span>
                        {/* </div> */}
                        </div>      
                </div>

                <div>
                        <div className={`${isReplyVisible ?'block':'hidden'} mt-10`}>
                            <div className="shadow-lg h-7 w-full"></div>
                            <Reply comment_id={props.id} setRefresh={setRefresh} setIsReplyVisible={setIsReplyVisible} setIsViewReplyVisible={setIsViewReplyVisible}/>  

                        </div>

                        <div
                             className={`${isViewReplyVisible?'flex':'hidden'} flex flex-col justify-center items-center mt-10`}> 
                            <div className="shadow-lg h-7 w-full "></div>
                             <ViewReply comment_id={props.id} refresh={refresh}/>
                             <div className="shadow-lg h-7 w-full mt-10"></div>
                            
                        </div>
                </div>

            </div>
        </div>
    );
}
 
export default ShowCommentItem;