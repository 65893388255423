// import blogPost from './assets/blogPost.jpg';
import { Outlet, Link } from "react-router-dom";

const MiniBlogPost = (props) => {
    return (  
        <div className="flex flex-col  justify-center items-center h-fit  rounded-lg">

                
                
                <div className="w-64 h-64 p-10 border-2 border-orange-400 rounded-md">  
                    <img src={`https://auxo-ortus-backend.onrender.com${props.image}`} className='hover:shadow-xl h-full w-full object-cover rounded-sm' alt='BlogPost Image' height='400' width='500'/>
                </div>
                <span className='text-center font-light text-4xl uppercase'>{props.topic}</span>
                <div className='grid grid-cols-1 gap-5'>
                    <span className='text-xl font-thin uppercase'>{props.author}</span>
   
                </div>
            
        </div>
    );
}
 
export default MiniBlogPost;