import axios from 'axios';
import React,{useState,useEffect} from 'react';

import NavBar from './NavBar';
import Home from './Home';
import Synopsis from './synopsis';
import AuxoOrtus from './AuxoOrtus';
import BrowseBlogs from './BrowseBlogs';
import EmailChat from './blog Post/emailChat';
import Footer from './blog Post/footer';
import ScrollToTop from './ScrollToTop';


const HomePage = () => {
    return ( 
        <div className='-mb-0.5 dark:bg-gray-900 text-black dark:text-white'>
            <ScrollToTop/>
            <NavBar />
            <Home/>
            {/* <Synopsis /> */}
            <AuxoOrtus />
            <BrowseBlogs />
            <EmailChat />
            <Footer/>
       </div>
     );
}
 
export default HomePage;