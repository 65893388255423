// import dummyImage from '../assets/cover-2.png'
import {useState,useEffect} from 'react';
import axios from 'axios';

const PostSnippetImage = (props) => {
    const [snippetImages,setSnippetImages]=useState('')

    useEffect(()=>{

        axios.get(`https://auxo-ortus-backend.onrender.com/api/snippetImages/${props.sectionID}`)
        .then(response=>{
            const snippetImages=response.data.map(res=>res.snippetImage)
            setSnippetImages(snippetImages)
        }
        )
        .catch(err=>
            // alert(err.message)
            console.log(err.message)
        )


    },[])

    return ( 
        snippetImages.length>0?(
            <div className="mt-10 w-1/2 h-1/2">
                    <img src={`https://auxo-ortus-backend.onrender.com${snippetImages}`} className='h-1/3 w-2/3'/>
            </div>
        )
        :null
     );
}
 
export default PostSnippetImage;

//src={`http://127.0.0.1:8000${props.}`}