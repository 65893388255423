import { useParams } from "react-router-dom"; 
import React,{ useState,useEffect } from "react";
import axios from "axios";
import { Outlet, Link } from "react-router-dom";





import NavBar from "../NavBar";
import PageDisplay from "../pageDisplay";
import BlogPostInfo from "./blogInfo";
import TableOfContents from "./tableOfContents";
import PostImage from "./postImage";
import PostSection from "./post-Section";
import SocialMediaSharing from "./socialMediaSharing";
import MiniBlogPost from "../miniBlogPost";
import Comments from "./comments";
import ShowComments from "./showComment";
import Footer from "./footer";
import ScrollToTop from "../ScrollToTop";
import Likes from "../likes";
import AurthorBio from "./aurthorBio";


// ID OR AN IDENTIFIER OF A POST IS PASSED TO THE IndividualBlogPost component.
const IndividualBlogPost = () => {

    const [refreshComments,setRefreshComments] = useState(false)

    const [isLoading,setIsLoading]=useState(true)

    


    

    

    

    const {id}=useParams();
    const [topic,setTopic]=useState('');
    const [category_id,setCategoryID]=useState('');
    const [category,setCategory]=useState('');
    const [author,setAuthor]=useState('');
    const [postImage,setPostImage]=useState('');
    const [datePosted,setDatePosted]=useState('');

    const [section,setSection]=useState([]);
    const [sectionTitles,setSectionTitles]=useState([]);
    const [sectionID,setSectionID]=useState([]);

    const [miniBlogPostauthor,setMiniBlogPostAuthor]=useState([]);
    const [miniBlogPostTopic,setMiniBlogPostTopic]=useState([]);
    const [miniBlogPostImage,setMiniBlogPostImage]=useState([]);
    const [miniBlogPostId,setMiniBlogPostId]=useState([])


    useEffect(()=>{
        axios.get(`https://auxo-ortus-backend.onrender.com/api/sections/${id}/`)
        .then(response=>{
            //for sections
            const sectionList=response.data.map(post=>post.section)
            setSection(sectionList);
            

            //for section titles
            const sectionIDList=response.data.map(post=>post.id)
            setSectionID(sectionIDList);
           

             //for section titles
             const sectionTitleList=response.data.map(post=>post.section_topic)
             setSectionTitles(sectionTitleList);
           
        })
        .catch(err=>{
            // alert(err.message);
            console.log(err.message)
        })

        axios.get(`https://auxo-ortus-backend.onrender.com/api/postDetails/${id}/`)
        .then(response=>{
            const authors=response.data.map(post=>post.author);
            setAuthor(authors);
           

            const category_id=response.data.map(post=>post.category);
            setCategoryID(category_id);

            axios.get(`https://auxo-ortus-backend.onrender.com/api/categories/${category_id}/`)
            .then(response=>{
                const categories=response.data.category;
                setCategory(categories);
               
            })
            .catch(err=>{
                // alert(err.message);
                console.log(err.message)
            })


            //fetches from the api endpoint for miniblogs under a certain category
            axios.get(`https://auxo-ortus-backend.onrender.com/api/postDetails/specific_category/${category_id}/`)
            .then(response=>{
                const miniBlogPostAuthors = response.data.map(post=>post.author);
                setMiniBlogPostAuthor(miniBlogPostAuthors)
               
    
                const miniBlogPostTopics = response.data.map(post=>post.topic);
                setMiniBlogPostTopic(miniBlogPostTopics)
                
    
                const miniBlogPostImages = response.data.map(post=>post.postImage);
                setMiniBlogPostImage(miniBlogPostImages)

                const miniBlogPostId=response.data.map(post=>post.id)
                setMiniBlogPostId(miniBlogPostId)
              
            })
            .catch(err => {
                // alert(err.message)
                console.log(err.message)
            })
    

            const dates=response.data.map(post=>post.formatted_date);
            setDatePosted(dates);
          

            const images=response.data.map(post=>post.postImage);
            setPostImage(images);
            
            const topics=response.data.map(post=>post.topic)
            setTopic(topics);

            setIsLoading(false);
        })
        .catch(err=>
        {
            // alert(err.message);
            console.log(err.message)
        })
  
    


       
 

      
     
    },[]);

    return (
        <div className="dark:bg-gray-900 text-black dark:text-white">
            <ScrollToTop/>
            <NavBar/>
            

            <div
                className="animate-slideUp transition-all duration-500 ease-out transform ">
                <PageDisplay display={topic}/>
            
            </div>

            {isLoading?
                <div className=" flex justify-center items-center flex-col animate-pulse">
                    <span className='text-4xl  font-extralight'>CONTENT LOADING...</span>
                    <span className="text-xl  font-extralight"> Please give this a second or two</span>
                </div>
                : 
                <div>
                    <BlogPostInfo category={category} author={author} time={datePosted}/>
                    <div className="mt-6 flex flex-col-reverse md:flex-row gap-10 mb-6 p-5">
                        <div className="flex-3 w-full md:w-2/3">
                            <PostImage postImage={postImage}/>

                            {/* for loop to get all sections of a post */}

                            {section.map((section,index)=>{
                                return (
                                    <div id={sectionTitles[index]}>
                                        
                                        <PostSection sectionText={section} section_id={sectionID[index]}/>
                                    </div>
                            )
                            })}
                            
                        
                        
                            <div class="w-full h-1 bg-gradient-to-b from-red-300 to-orange-400 border-x-orange-400 shadow-lg rounded"></div>

                        </div>
                    

                        <div className="flex-2 w-full md:w-1/3">
                            <span className="text-2xl md:text-4xl font-thin hover:text-orange-400 dark:text-white text-gray-800">Table of Contents</span>
                            <br></br>
                            <br></br>
                            <TableOfContents sectionTitles={sectionTitles} id={id}/>
                        </div>
                    
                        
                    </div>

                    {/* like button implementation */}
                    <Likes blogPost_id={id}/>

                    <AurthorBio/>

                


                    
                    <SocialMediaSharing topic={topic} blog={''} />
                    
                    <div className="w-full flex flex-col md:flex-row md:flex-nowrap gap-6 mt-20 h-auto p-5">

                        {miniBlogPostTopic.map((miniBlogPostTopic,index)=>
                            { 
                    
                                return (
                                    <div onClick={()=>{
                                        window.location.reload();
                                    }}>
                                        <Link 
                                            smooth
                                            to={`/blogs/${miniBlogPostId[index]}`}>
                                                {/* {window.loca.reload()load()} */}
                                            <MiniBlogPost topic={miniBlogPostTopic} author={miniBlogPostauthor[index]} image={miniBlogPostImage[index]}/>
                                        </Link>
                                    </div>)
                            }
                            
                            )}
                    
                    </div>

                    <div className=" mt-10 md:mt-40 p-5">
                        <Comments id={id} setRefreshComments={setRefreshComments}/>
                        {/* for loop to show comments */}
                        <ShowComments  id={id} refreshComments={refreshComments}/>
                    </div>
            </div>
            }
            <Footer />
            <Outlet/>

        </div>
      );
}
 
export default IndividualBlogPost;