import { useEffect,useState } from "react";
import axios from "axios";

const PostSnippet = (props) => {

    const [snippet,setSnippet]=useState([]);

    useEffect(() =>{
        axios.get(`https://auxo-ortus-backend.onrender.com/api/snippets/${props.sectionID}`)
        .then(response=>{
            const snippets=response.data.map(post=>post.snippet)
            setSnippet(snippets);

        })
        .catch(err=>console.log(err.message));
    },[]);

    return ( 
            snippet.length > 0 ? (

            <div className=" dark:text-black mt-10 border-2 h-1/3 w-1/3  p-5 rounded-xl hover:bg-gradient-to-r from-red-300 to-orange-300 bg-orange-200 shadow-md ">
            { snippet.map((snippet)=>(
                    <span className=" text-sm md:text-lg font-mono hover:text-orange-400 whitespace-normal">{snippet}</span> 
                ))
                }
            </div>

        )
        :null
           
        
     );
}
 
export default PostSnippet;