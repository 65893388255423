import {useEffect,useState} from 'react';
import axios from 'axios';

import ShowCommentItem from './showCommentItem';




const ShowComments = ({id,refreshComments}) => {

    const[comment,setComment]=useState([])
    const[name,setName]=useState([])
    const[date_commented,setDateCommented]=useState([])
    const[comment_id,setComment_id]=useState([])
 

 


    useEffect(()=>{
        axios.get(`https://auxo-ortus-backend.onrender.com/comments_section/comments/${id}/`)
        .then(response=>{
            


            const comments=response.data.map(res=>res.comment)
            setComment(comments)
         

            const names=response.data.map(res=>res.commenter_name)
            setName(names)
           

            const date_commented=response.data.map(res=>res.formatted_date)
            setDateCommented(date_commented)
            

            
            const comments_id=response.data.map(res=>res.id)
            setComment_id(comments_id)
        
        })
        .catch(err=>{
            // alert(err.message)
            console.log(err.message)
        })

        
    },[refreshComments])


    return ( 
        <div >
            {comment.length===0 ? 
                <span className="font-thin text-4xl content-center mt-100">NO COMMENTS YET!!</span>:
                comment.map((comment,index)=>{
                return(<ShowCommentItem username={name[index]} date_commented={date_commented[index]} comment={comment} id={comment_id[index]}/>)
            })}
            
        </div>
     );
}
 
export default ShowComments;