import ItemTableOfContents from "./item-TableOfContents";
import { useState } from "react";

const TableOfContents = ({sectionTitles,id}) => {
   
    return ( 
        <div className="dark:bg-gray-900 text-black dark:text-white">
            {/* for loop with the various items/keyThemes displayed */}
            {sectionTitles.map((title)=>{
                return(
                    <div className="p-11 pl-0" id={title}>
                        <ItemTableOfContents keyThemes={title} id={id}/>
                    </div>

                )
            })}
           
            
        </div>
     );
}
 
export default TableOfContents;