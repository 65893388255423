import GitHubIcon from '@mui/icons-material/GitHub';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInOutlinedIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Facebook from '@mui/icons-material/Facebook';
import { WhatsappIcon } from 'react-share';


import { 
    FacebookShareButton, 
    TwitterShareButton,
    LinkedinShareButton, 
    WhatsappShareButton,
    InstapaperShareButton,
    EmailShareButton,

} from 'react-share';

const SocialMediaSharing = ({topic,blog}) => {
    const windowURL=window.location.href;
    const shareMessage=`Check out this new post titled ${topic}`


    return ( 
        <div>
            <span className="text-2xl font-thin hover:text-orange-400">Share this {blog} on </span>
            <div className='flex overflow-hidden space-x-16 group'>


                <div className='flex space-x-16 animate-loop-scroll  group-hover:paused'>
                    {/* <div className="flex flex-col ">
                        <InstagramIcon className=' self-center' sx={{fontSize:50}}/>
                        <span className="text-2xl font-thin hover:text-orange-400">Instagram</span>
                    </div> */}

                        <FacebookShareButton url={windowURL} title={shareMessage}>
                            <div className="flex flex-col ">
                                <Facebook className=' self-center' sx={{fontSize:50}}/>
                                <span className="text-2xl font-thin hover:text-orange-400">Facebook</span>
                            </div>
                        </FacebookShareButton>


                    
                        <TwitterShareButton url={windowURL} title={shareMessage}>
                            <div className="flex flex-col ">
                                <XIcon className=' self-center' sx={{fontSize:50}}/>
                                <span className="text-2xl font-thin hover:text-orange-400">X</span>
                            </div>
                        </TwitterShareButton>
                  
                        <WhatsappShareButton url={windowURL} title={shareMessage}>
                            <div className="flex flex-col ">
                                <WhatsAppIcon className=' self-center' sx={{fontSize:50}}/>
                                <span className="text-2xl font-thin hover:text-orange-400">WhatsApp</span>
                            </div>
                        </WhatsappShareButton >

                        <LinkedinShareButton url={windowURL} title={shareMessage}>
                            <div className="flex flex-col ">
                                <LinkedInOutlinedIcon className=' self-center' sx={{fontSize:50}}/>
                                <span className="text-2xl font-thin hover:text-orange-400">LinkedIn</span>
                            </div>
                        </LinkedinShareButton>
                </div>



                <div className='flex space-x-16 animate-loop-scroll group-hover:paused aria-hidden:"true" '>
                    {/* <div className="flex flex-col ">
                        <InstagramIcon className=' self-center' sx={{fontSize:50}}/>
                        <span className="text-2xl font-thin hover:text-orange-400">Instagram</span>
                    </div> */}

                        <FacebookShareButton url={windowURL} title={shareMessage}>
                            <div className="flex flex-col ">
                                <Facebook className=' self-center' sx={{fontSize:50}}/>
                                <span className="text-2xl font-thin hover:text-orange-400">Facebook</span>
                            </div>
                        </FacebookShareButton>


                    
                        <TwitterShareButton url={windowURL} title={shareMessage}>
                            <div className="flex flex-col ">
                                <XIcon className=' self-center' sx={{fontSize:50}}/>
                                <span className="text-2xl font-thin hover:text-orange-400">X</span>
                            </div>
                        </TwitterShareButton>
                  
                        <WhatsappShareButton url={windowURL} title={shareMessage}>
                            <div className="flex flex-col ">
                                <WhatsAppIcon className=' self-center' sx={{fontSize:50}}/>
                                <span className="text-2xl font-thin hover:text-orange-400">WhatsApp</span>
                            </div>
                        </WhatsappShareButton >

                        <LinkedinShareButton url={windowURL} title={shareMessage}>
                            <div className="flex flex-col ">
                                <LinkedInOutlinedIcon className=' self-center' sx={{fontSize:50}}/>
                                <span className="text-2xl font-thin hover:text-orange-400">LinkedIn</span>
                            </div>
                        </LinkedinShareButton>
                </div>



                <div className='flex space-x-16 animate-loop-scroll group-hover:paused aria-hidden:"true" '>
                    {/* <div className="flex flex-col ">
                        <InstagramIcon className=' self-center' sx={{fontSize:50}}/>
                        <span className="text-2xl font-thin hover:text-orange-400">Instagram</span>
                    </div> */}

                        <FacebookShareButton url={windowURL} title={shareMessage}>
                            <div className="flex flex-col ">
                                <Facebook className=' self-center' sx={{fontSize:50}}/>
                                <span className="text-2xl font-thin hover:text-orange-400">Facebook</span>
                            </div>
                        </FacebookShareButton>


                    
                        <TwitterShareButton url={windowURL} title={shareMessage}>
                            <div className="flex flex-col ">
                                <XIcon className=' self-center' sx={{fontSize:50}}/>
                                <span className="text-2xl font-thin hover:text-orange-400">X</span>
                            </div>
                        </TwitterShareButton>
                  
                        <WhatsappShareButton url={windowURL} title={shareMessage}>
                            <div className="flex flex-col ">
                                <WhatsAppIcon className=' self-center' sx={{fontSize:50}}/>
                                <span className="text-2xl font-thin hover:text-orange-400">WhatsApp</span>
                            </div>
                        </WhatsappShareButton >

                        <LinkedinShareButton url={windowURL} title={shareMessage}>
                            <div className="flex flex-col ">
                                <LinkedInOutlinedIcon className=' self-center' sx={{fontSize:50}}/>
                                <span className="text-2xl font-thin hover:text-orange-400">LinkedIn</span>
                            </div>
                        </LinkedinShareButton>
                </div>



                <div className='flex space-x-16 animate-loop-scroll group-hover:paused aria-hidden:"true" '>
                    {/* <div className="flex flex-col ">
                        <InstagramIcon className=' self-center' sx={{fontSize:50}}/>
                        <span className="text-2xl font-thin hover:text-orange-400">Instagram</span>
                    </div> */}

                        <FacebookShareButton url={windowURL} title={shareMessage}>
                            <div className="flex flex-col ">
                                <Facebook className=' self-center' sx={{fontSize:50}}/>
                                <span className="text-2xl font-thin hover:text-orange-400">Facebook</span>
                            </div>
                        </FacebookShareButton>


                    
                        <TwitterShareButton url={windowURL} title={shareMessage}>
                            <div className="flex flex-col ">
                                <XIcon className=' self-center' sx={{fontSize:50}}/>
                                <span className="text-2xl font-thin hover:text-orange-400">X</span>
                            </div>
                        </TwitterShareButton>
                  
                        <WhatsappShareButton url={windowURL} title={shareMessage}>
                            <div className="flex flex-col ">
                                <WhatsAppIcon className=' self-center' sx={{fontSize:50}}/>
                                <span className="text-2xl font-thin hover:text-orange-400">WhatsApp</span>
                            </div>
                        </WhatsappShareButton >

                        <LinkedinShareButton url={windowURL} title={shareMessage}>
                            <div className="flex flex-col ">
                                <LinkedInOutlinedIcon className=' self-center' sx={{fontSize:50}}/>
                                <span className="text-2xl font-thin hover:text-orange-400">LinkedIn</span>
                            </div>
                        </LinkedinShareButton>
                </div>
             
             
             
             
             

             
            </div>
            
            

        </div>
     );
}
 
export default SocialMediaSharing;