import React,{useEffect,useState} from 'react';
import axios from 'axios';
import { Outlet, Link } from "react-router-dom";

import MiniBlogPost from "../miniBlogPost";
import NavBar from "../NavBar";
import PageDisplay from "../pageDisplay";
import CategoryHeading from "./categoryHeading";
import SubHeading from "./subHeading";
import FeaturedPost from "./featuredPost";
import SocialMediaSharing from "../blog Post/socialMediaSharing";
import Footer from "../blog Post/footer";
import ScrollToTop from '../ScrollToTop';
import Carousel from './carousel';

const Articles = () => {
    const [isLoading,setIsLoading] =useState(true);
    
    const [category,setCategory]=useState([])
    const [category_id,setCategoryID]=useState([])
    const [postDetails_cat_id,setPostDetails_cat_id]=useState([])
    const [postDetails_id,setPostDetailsID]=useState([])
    const [topic,setTopic]=useState([])
    const [author,setAuthor]=useState([])
    const [snippet,setSnippet]=useState([])
    const [featured,setFeatured]=useState([])
    const [imagePost,setImagePost]=useState([])

    const featuredTopics=topic.filter((_,index)=>featured[index]===true)
    const featuredImages=imagePost.filter((_,index)=>featured[index]===true)
    const featuredPostCategories=postDetails_cat_id.filter((_,index)=>featured[index]===true)
    // alert(featuredPostCategories)
    const featuredCategories=postDetails_cat_id.filter((_,index)=>featured[index]===true)
    const featuredAuthors=author.filter((_,index)=>featured[index]===true)
    const featuredPostID=postDetails_id.filter((_,index)=>featured[index]==true)


    const featuredPOSTCategories=[]









    for(var i=0;i<featuredPostCategories.length;i++){
        for(var j=0;j<category_id.length;j++){
            if(featuredPostCategories[i]==category_id[j]){
                featuredPOSTCategories.push(category[j])
               
            }
       
    }}
    // featuredPOSTCategories.reverse()
   
    
    
    

    useEffect(()=>{

        axios.get(`https://auxo-ortus-backend.onrender.com/api/postDetails/`)
        .then(response=>{
            const authors=response.data.map(post=>post.author)
            setAuthor(authors)
            

            const featuredPost=response.data.map(post=>post.featuredPost)
            setFeatured(featuredPost)
          
            const imagePost=response.data.map(post=>post.postImage)
            setImagePost(imagePost)
          

            const cat_id=response.data.map(post=>post.category)
            setPostDetails_cat_id(cat_id)
            // alert(cat_id)
       

            const topic=response.data.map(post=>post.topic)
            setTopic(topic)
        

            const postDetailsID=response.data.map(post=>post.id)
            setPostDetailsID(postDetailsID)
            // alert(postDetailsID)
           
        })
        .catch(err=>
            // alert(err.message)
            console.log(err.message)
        )

        axios('https://auxo-ortus-backend.onrender.com/api/categories/')
        .then(response=>{
            const categories=response.data.map(post=>post.category)
            setCategory(categories)
            

            const categories_id=response.data.map(post=>post.id)
            setCategoryID(categories_id)
            // alert(categories_id)

            setIsLoading(false)
            

            
        })
        .catch(err=>{
            // alert(err.message)
            console.log(err.message)
        })

        

        
    },[])

    
    return ( 

        
        <div className="dark:bg-gray-900 text-black dark:text-white">
            <ScrollToTop/>
            <NavBar/>
            <PageDisplay display="ARTICLES"/>

        {isLoading ? 
          <div className=" flex justify-center items-center flex-col mt-10 text-orange-400 animate-pulse">
            <span className='text-4xl  font-extralight'>CONTENT LOADING...</span>
            <span className="text-xl  font-extralight"> Please give this a second or two</span>
        </div>
        :
            <div>

            <div className='flex flex-col gap-10'>
                <SubHeading heading="FEATURED POSTS"/>

           



                <div className="justify-between p-20  rounded-md">
                    {/* grid grid-cols-3 gap-10 */}
                    <Carousel autoslide={true} autoSlideInterval={1000} featuredImages={featuredImages} featuredAuthors={featuredAuthors} featuredTopics={featuredTopics} featuredCategories={featuredPOSTCategories} featuredPostID={featuredPostID}>
                        {/* {topic.map((topic,index)=>{
                            return(
                                <div >
                                 {featured[index] === "true" || featured[index] === true? (<FeaturedPost image={imagePost[index]} topic={topic} author={author[index]} category={category[index]} snippet="Have a read of this amazing sports article"/>) :null }
                                </div>
                        )})} */}
                    </Carousel>
                         
                </div>

            </div>

            <div>
                <SubHeading heading="CATEGORIES"/>
            </div>

          
            <div>
                {/* For loop that returns the div below (all the category titles)*/}
                {category_id.map((cat_id,index)=>{
                    for(var i=0;i<postDetails_cat_id.length;i++){
                        if(cat_id == postDetails_cat_id[i]){
                            // alert(cat_id)
                                return (
                                        <div id={category[index]}>
                                            <div >
                                                <CategoryHeading category={category[index]} />
                                            </div>
                                            {/* For loop that returnd the div below(all the miniblog posts) */}
                                            
                                            
                                            <div className={`grid  gap-10 ${postDetails_cat_id.filter(id=>id===cat_id).length===1?'lg:grid-cols-1 justify center':'lg:grid-cols-3'} ` }>
                                            {postDetails_cat_id.map((postDetails_cat_id,index_cat_id) =>{
                                                if(postDetails_cat_id === cat_id){
                                                   
                                                    
                                                    return(
                                                        <div className="mt-10">
                                                            <Link
                                                            smooth
                                                            to={`/blogs/${postDetails_id[index_cat_id]}`}>
                                                                <MiniBlogPost topic={topic[index_cat_id]} author={author[index_cat_id]} image={imagePost[index_cat_id]}/>
                                                            </Link>
                                                        </div>
                                                        )
                                                }
                                            }
                                        )
                                                
                                            
                                            }
                                            </div>

                                        </div>
                            )}
                    }
                })}
                </div>
                

                <div className="mt-20">
                    <SocialMediaSharing/> 
                </div>
            </div>
            }

            <Footer />
            <Outlet/>

        </div>
     );
}

 
export default Articles;