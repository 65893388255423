import {useEffect,useState} from 'react';
import axios from 'axios';

const Reply = ({comment_id,setRefresh,setIsReplyVisible,setIsViewReplyVisible}) => {
    const[commenter_name,setCommenterName] = useState('')
    const[replier_name,setReplierName] = useState('')
    const[email,setEmail] = useState('')
    const[reply,setReply] = useState('')

    //state to trigger refresh
    // const [refresh, setRefresh] = useState(false);

    useEffect(() =>{

        axios.get(`https://auxo-ortus-backend.onrender.com/comments_section/comments/particular_comment/${comment_id}/`)
        .then(response=>{
            const name=response.data.commenter_name;
            setCommenterName(name);
           
        })
        .catch(err=>{
            // alert(err.message)
            console.log(err.message)
        })
    

    },[comment_id])
   


    
    const handleReplySubmit=(e)=>{
        
        e.preventDefault()
        
        const reply_comment={
            comment:comment_id,
            reply_name:replier_name,
            email_reply:email,
            reply:reply,
        }

        axios.post('https://auxo-ortus-backend.onrender.com/comments_section/replies/post_reply/',reply_comment)
        .then(response=>{
            // alert('Replied successfully',response.data)
            setEmail('');
            setReplierName('');
            setReply('');

            setRefresh(prev=>!prev);
            setIsReplyVisible(prev=>!prev);
            setIsViewReplyVisible(true);
        })
        .catch(err=>{
            // alert(err.message)
            console.log(err.message)
        })
    }

  

    
        
      






    return ( 
        <form onSubmit={handleReplySubmit} className="dark:text-black"> 
            <div className='ml-5'>
                <span className="dark:text-white text-xl font-thin">Reply to <span className='text-orange-400 font-light dark:hover:text-white hover:text-neutral-800'>{commenter_name}</span></span>
            </div>
            <div className="mt-8 ml-4 flex flex-col md:flex-row gap-6 md:gap-8">
                <div className="w-full md:w-1/4">
                    <input className=" hover:bg-gradient-to-r from-orange-100 to-orange-200 rounded-md hover:border-0 h-10 w-full p-2 bg-gray-100 focus:ring-2 focus:ring-orange-400" 
                            type="text" 
                            placeholder="username"
                            value={replier_name} 
                            onChange={(e)=>setReplierName(e.target.value)}/>
                </div>


                <div className="w-full md:w-1/4">
                    <input className=" hover:bg-gradient-to-r from-orange-100 to-orange-200 rounded-md hover:border-0 h-10 w-full p-2 bg-gray-100 focus:ring-2 focus:ring-orange-400" 
                            type="text" 
                            placeholder="email address"
                            value={email}
                            onChange={(e)=>setEmail(e.target.value)}/> 
                </div>


                <div className="w-full md:w-2/3">
                    <input className=" hover:bg-gradient-to-r from-orange-100 to-orange-200 rounded-md hover:border-0 h-10 w-full p-2 bg-gray-100 focus:ring-2 focus:ring-orange-400" 
                            type="text" 
                            placeholder="Your Comment"
                            value={reply}
                            onChange={(e)=>setReply(e.target.value)}/> 
                </div>


                <button className="border border-s-2 bg-orange-300 rounded-xl h-9 w-1/4 hover:bg-gradient-to-l from-red-400 to-orange-600 hover:border-0 font-light" 
                        type="submit ">Reply
                </button>
            </div>
        </form>
     );
}
 
export default Reply;