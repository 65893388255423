import {useState,useEffect} from 'react'
import axios from 'axios'


const Comments = ({id,setRefreshComments}) => {
    const [commenter_name,setCommenterName] =useState('')
    const [comment,setComment] =useState('')
    const[email,setEmail] =useState('')
    

    const handleCommentSubmit=(e)=>{
        //find out what this means
        e.preventDefault();

        const newComment={
            commenter_name: commenter_name,
            email_address:email,
            comment:comment,
            blogPost:id,
        }

        axios.post('https://auxo-ortus-backend.onrender.com/comments_section/comments/post_comment/',newComment)
        .then(response=>{
            // alert('Comment added successfully',response.data)
            setComment('')
            setCommenterName('')
            setEmail('')

            setRefreshComments(prev=>!prev)
        })
        .catch(err=>{
            // alert(err.message)
            console.log(err.message)
        })
    }
 


    return ( 
        <form  onSubmit={handleCommentSubmit}>
            <span className="text-2xl font-thin">COMMENTS</span>
            <div className="dark:text-black mt-8 flex flex-col md:flex-row gap-6 md:gap-8">
                <div className="w-full md:w-1/4">
                    <input className=" hover:bg-gradient-to-r from-orange-100 to-orange-200 rounded-md hover:border-0 h-10 w-full p-2 bg-gray-100 focus:ring-2 focus:ring-orange-400" 
                            type="text" 
                            placeholder="username"
                            onChange={(e)=>setCommenterName(e.target.value)}/>
                </div>


                <div className="w-full md:w-1/4">
                    <input className=" hover:bg-gradient-to-r from-orange-100 to-orange-200 rounded-md hover:border-0 h-10 w-full p-2 bg-gray-100 focus:ring-2 focus:ring-orange-400" 
                            type="text" 
                            placeholder="email address"
                            onChange={(e)=>setEmail(e.target.value)}/>
                </div>


                <div className="w-full md:w-2/3">
                    <input className=" hover:bg-gradient-to-r from-orange-100 to-orange-200 rounded-md hover:border-0 h-10 w-full p-2 bg-gray-100 focus:ring-2 focus:ring-orange-400" 
                            type="text" 
                            placeholder="Your Comment"
                            onChange={(e)=>setComment(e.target.value)}/>
                </div>


                <button className="border border-s-2 bg-orange-300 rounded-xl h-9 w-1/4 hover:bg-gradient-to-l from-red-400 to-orange-600 hover:border-0 font-light" 
                        type="submit ">Comment</button>
            </div>
        </form>
     );
}
 
export default Comments;