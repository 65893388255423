import React from 'react';
import { useState,useEffect } from 'react';
import { Outlet, Link } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import FeaturedPost from './featuredPost';

function Carousel(props,{autoSlide=true,autoSlideInterval=3000}) {
    const [currentComponent,setCurrentComponent]=useState(0)

    const prevComponent=()=>{
        setCurrentComponent(currentComponent === 0 ? props.featuredTopics.length-1: currentComponent -1)
    }

    const nextComponent=()=>{
        setCurrentComponent(currentComponent === props.featuredTopics.length-1 ? 0 : currentComponent + 1)
    }



    useEffect(()=>{
        
        const interval=setInterval(()=>nextComponent(),2000);
        return ()=>clearInterval(interval);  
    },[currentComponent])

  return (
    <div className="relative w-full">

                <div className=" ease-in-out  animate-fadeIn w-full md:3/4 items-center justify-center ">
                <Link 
                    smooth
                    to={`/blogs/${props.featuredPostID[currentComponent]}`}>
                    <FeaturedPost 
                        topic={props.featuredTopics[currentComponent]} 
                        image={props.featuredImages[currentComponent]} 
                        category={props.featuredCategories[currentComponent]} 
                        author={props.featuredAuthors[currentComponent]}
                        ID={props.featuredPostID[currentComponent]}/>
                </Link>
                
                </div>
            
        

        
        
       
        <Outlet/>

        {/* indicator section */}
        <div className='absolute bottom-4 right-0 left-0'>
            <div className="flex items-center justify-center gap-2">
                {props.featuredTopics.map((_,i)=>(
                    <div 
                        className={`
                        transition-all w-3 h-3 bg-white rounded-full
                        ${currentComponent=== i? 'p-3':'bg-opacity-50'}`}/>
                ))}

            </div>


        </div>


   


    </div>
  )
}

export default Carousel
