// import logo from './assets/auxo-blog-2.png';
import logo from './assets/favicon.ico';
import './assets/AuxoOrtus.css';
const AuxoOrtus = () => {
    return ( 
        <div className='-mt-48'>
            <div className='flex flex-col mt-80'>

            <div className="flex justify-center items-center ">
                <div className="flex flex-col gap-10 justify-center items-center">
                    <div className='animate-slideUp'><span className='text-6xl md:text-8xl font-extralight self-center'>AUXO ORTUS</span></div>
                    <img className='h-100 width-100' src={logo} alt='auxo-ortus logo'/>
                </div>
            </div>

                <div className="justify-center items-center flex">
                    <p className='break-words whitespace-normal font-light p-10 self-center text-lg '>
                        {/* place a for loop here that loops through each section and outputs a paragrap tag for each section */}
                        Auxo (Greek): Refers to growth or increase, and in Greek mythology, Auxo was a goddess associated with growth and prosperity.
                        Ortus (Latin): Means rising, emergence, or dawn, symbolizing new beginnings.

                        Together, Auxo Ortus would represent "growing emergence" or "rising growth," which perfectly aligns with the themes of personal adventure, storytelling, and your passion for space. It suggests that your blog is a place where new ideas, experiences, and growth are continuously emerging—much like the stars in space or personal journeys. It's a unique, poetic, and fitting name!
                    </p>
                </div>
            </div>
        </div>
     );
}
 
export default AuxoOrtus;