import { useEffect,useState } from "react";
import axios from "axios";

const EmailChat = () => {
    const [email,setEmail] =useState('');
    const [isEmailSent,setIsEmailSent] = useState(false);

    const handleSubmitEmail=(e)=>{
        e.preventDefault();

        const newEmail={
            email:email,
        }

        axios.post('https://auxo-ortus-backend.onrender.com/contact_form/subscribing_link/',newEmail)
        .then(
            setEmail(''),
            setIsEmailSent(true)
        )
        .catch(err=>
            console.log(err.message)
            // alert(err.message)
        )

    }

    return ( 
        
        <div className="p-24">
            <div className="mb-10 ">
                <span className="text-5xl font-thin ">SUBSCRIBE TO AUXO ORTUS!</span>
            </div>
            {isEmailSent===false ? 
            <div>
            
            <div className="mb-10">
                <p className="text-xl font-extralight ">Get more personal via email. Type
                    in your email address below and
                    hit subscribe to get all the latest posts on AUXO ORTUS.
                </p>
            </div>

            <form onSubmit={handleSubmitEmail}>
                <input 
                    type="text" 
                    value={email}
                    onChange={(e)=>setEmail(e.target.value)}
                    className="dark:text-black rounded-full border border-slate-600 h-12 w-full hover:border-red-500 md:w-1/2 " 
                    placeholder="chat@email.com"/>

                <button type="submit" className="mt-4 p-2 bg-orange-400 text-white rounded-xl">Subscribe</button>
            </form>
        </div>
        :
        <div className="">
            <span className="text-xl font-extralight animate-pulse">You have successfully subscribed to AUXO ORTUS. Please check your email for your subscription message!</span>
        </div>
}
</div>

    );
}
 
export default EmailChat;