import axios from 'axios';
import React,{useState,useEffect} from 'react';
import { Outlet, Link } from "react-router-dom";
import {HashLink} from 'react-router-hash-link';
import {useLocation} from 'react-router-dom';
import './assets/BrowseBlogs.css';
import { Button } from '@mui/material';

import MiniBlogPost from './miniBlogPost';
import ViewAllButton from './viewAllButton';
import FeaturedPost from './featuredBlogPosts/featuredPost';


// getting /loading all the attributes for mini blogPost



const BrowseBlogs = () => {
    const [isLoading,setIsLoading]=useState(true);

    const [postDetailsCategoryID,setPostDetailsCategoryID]=useState([])
    const [author,setAuthor]=useState([])
    const [imagePost,setImagePost]=useState([])
    const [featured,setFeatured]=useState([])
    const [topic,setTopic]=useState([])
    const [postID,setPostID]=useState([])

    const [category,setCategory]=useState([])
    const [category_id,setCategoryID]=useState([])
    // const [error, setError] = useState(null); 

    const location=useLocation()
    

 


    
    useEffect(()=>{
        
        axios.get('https://auxo-ortus-backend.onrender.com/api/postDetails/')
        .then(response=>{
            const topics=response.data.map(post=>post.topic)
            setTopic(topics)

            const categories=response.data.map(post=>post.category)
            setPostDetailsCategoryID(categories)
            // alert(`POST DETAILS CAT IDS ARE${categories}`)

            const authors=response.data.map(post=>post.author)
            setAuthor(authors)
            // alert(authors)

            const images=response.data.map(post=>post.postImage)
            setImagePost(images)

            const featurePosts=response.data.map(post=>post.featuredPost)
            setFeatured(featurePosts)
            // alert(featurePosts)

            const postID=response.data.map(post=>post.id)
            setPostID(postID)
            // alert(postID)

            
            
        })
        .catch(err=>{
            // alert(err.message)
            console.log(err.message)
        })

        axios.get('https://auxo-ortus-backend.onrender.com/api/categories/')
        .then(response=>{
            const cats=response.data.map(post=>post.category)
            setCategory(cats)
            
            const cats_id=response.data.map(post=>post.id)
            setCategoryID(cats_id)
            // alert(`CATEGORY ID ARE ${cats_id}`)


            setIsLoading(false)
           
        })
        .catch(err=>
            console.log(err.message)
        )
       

        



    
    },[]);

    

    return (
       
        <div className="p-24">
            <a href='/' style={{fontSize:'50px',fontWeight:'lighter',textDecoration:'none',color:'black'}} ><Link to='/articles' className="dark:text-white hover:text-orange-400 hover:font-mono">BROWSE ALL POSTS...</Link></a>

            { isLoading && <div className='text-2xl justify-center items-center font-extralight'> LOADING...</div>}

            <div className='main-div'>
                <div className="grid grid-cols-1 gap-36 lg:grid-cols-3  justify-center items-center">
                
                        {postDetailsCategoryID.map((postDetailsCategoryID,index)=>{
                            // alert(`PARTICULAR POST DETAIL CAT ID IS ${postDetailsCategoryID}`)
                            // alert(index)
                            if(featured[index])
                                {   
                                    const cat=category.filter((_,i)=>postDetailsCategoryID===category_id[i])
                                    // alert(cat)
                                            return(
                                                <HashLink
                                                smooth
                                                to={`/blogs/${postID[index]}`}>
                                                    <FeaturedPost image={imagePost[index]} topic={topic[index]} author={author[index]} category={cat}/>
                                                </HashLink>)
                                        
                                    }
                                   
                                    
                                    
                            
                        })}

                   
                    


                </div>
                
            </div>

            <ViewAllButton />
            <Outlet/>
            
            
        </div>
        
      );
}
 
export default BrowseBlogs;