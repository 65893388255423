import React from 'react'
import { useParams } from "react-router-dom"; 
import { useState,useEffect } from "react";
import axios from "axios";

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';

const Likes = ({initialLikes=0,blogPost_id}) => {

    //for likes model
  const [likes,setLikes]=useState(initialLikes);
  const [liked,setLiked]=useState(true);

  const handleLikes=()=>{
    setLiked(!liked)
    
    if(liked){
      setLikes(likes+1)
      axios.post(`https://auxo-ortus-backend.onrender.com/api/postDetails/likes_increase/${blogPost_id}/`)
      .then()
      .catch(err=>{
        alert(err.message);
      })
    }else{
      setLikes(likes-1)
      axios.post(`https://auxo-ortus-backend.onrender.com/api/postDetails/likes_decrease/${blogPost_id}/`)
      .then()
      .catch(err=>{
        alert(err.message);
      })
    }
   
       
    }



  return (
    <div className='mt-20 mb-28'>
      <button
        onClick={handleLikes}>
                {liked? 
                  <div>
                    <FavoriteBorderOutlinedIcon fontSize="large"/>
                      <span className="text-lg font-extralight"><span className="text-red-500">Like</span> this post</span>
                  </div>
                      : 
                  <div>
                      <FavoriteIcon sx={{color:'red'}} fontSize='large'/>
                        <span className="text-lg font-extralight"><span className="text-red-500">Unlike</span> this post</span>
                  </div>}
                   
                </button> 
      
    </div>
  )
}

export default Likes
