import {HashLink} from 'react-router-hash-link';
import {useLocation} from 'react-router-dom';

const ItemTableOfContents = (props) => {

    const location=useLocation()

    return ( 
        <div>
            <HashLink
                smooth
                to={location.pathname===`/blogs/${props.id}` ?`#${props.keyThemes}`:`#${props.keyThemes}`}>
                <span className="text-lg font-light hover:text-orange-400 ">{props.keyThemes}</span>
            </HashLink>
            {/* <div class="w-1/3 h-1 bg-gradient-to-b from-red-300 to-orange-400 border-x-orange-400 shadow-lg rounded"></div> */}
        </div>
     );
}
 
export default ItemTableOfContents