import React from 'react';
import {useEffect,useState} from 'react';
import axios from 'axios';



import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const ViewReply = ({comment_id,refresh}) => {
       
    const[replies,setReplies]=useState([])
    const[replier_name,setReplierName]=useState([])
    const[date_replied,setDateReplied]=useState([])

    // const [refresh,setRefresh] = useState(false)


    useEffect(()=>{
    
        axios.get(`https://auxo-ortus-backend.onrender.com/comments_section/replies/${comment_id}/`)
        .then(response=>{
            const reply_name=response.data.map(reply=>reply.reply_name)
            setReplierName(reply_name);
           
           

            const reply=response.data.map(reply=>reply.reply)
            setReplies(reply)
            
          

            const date=response.data.map(reply=>reply.formatted_date)
            setDateReplied(date)
          
        })
        .catch(err=>
            console.log(err.message)
        )
    },[comment_id,refresh])

 

  return (
    <div className='dark:bg-gray-900 bg-slate-100 flex flex-col gap mt-1'>
         
       
        {replies.length!==0?replies.map((reply,index)=>{
            return(
            // alert(reply);
            <div className="flex gap-20 mt-20">
            {/* {alert(reply)} */}
            
             <div className='flex flex-col w-20 gap-5'>
                    {/* self-center */}
                <AccountCircleIcon className=' self-center' sx={{fontSize:'65px'}} />
                <span className=" font-extralight self-center" >{replier_name[index]}</span>
            </div>
            
          

            <div className='flex flex-col gap-3'>
                <p className="p-5 font-extralight text-lg">
                    {reply}
                </p>
                
             
                <div className="grid grid-cols-1 ml-5">
                    <span className='font-thin hover:text-orange-400'>{date_replied[index]}</span>
                </div>

            </div>
        

        </div>
            // <ShowReplyItem username={replier_name[index]} comment={reply} date_commented={date_replied[index]}/>
        )}):<div className='justify-center items-center mt-8'>
                <span className='text-center font-extralight text-xl '>NO REPLIES</span>
            </div>}
        
      
    </div>
  )
}

export default ViewReply
