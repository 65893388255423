import React from 'react';
import Profile from './profileTest.jpg';

function AurthorBio() {
  return (
    <div className='flex flex-col gap-5 justify-center items-center'>
        <span className="text-3xl  font-extralight">ABOUT THE AUTHOR <span className='font-semibold hover:text-orange-400'>SELINA KUDADZE</span></span>
        <div className='max-w-sm border-2 border-orange-300 rounded-lg'>
            <img src={Profile} className='h-auto rounded-lg object-contain'/>
        </div>

        <div className="flex justify-center items-center p-10">
            <p className='text-xl hover:text-orange-400 font-extralight '>After this encounter, they journey to the Pyramids where they 
                meet a monk in a monastery. Here, the Alchemist shows Santiago the
                materialization of his (The Alchemist’s) personal Legend. 
                He turns lead into gold and gifts the pieces to the monk and 
                Santiago. Here, we realize that those who have obtained their 
                personal legend must help those in search of theirs. Santiago is 
                yet again face-to-face with trouble as he encounters a man who gave
                up on his personal legend. After beating and robbing Satiago of this gifted 
                gold; he reveals that he gave up on his search for his own treasure. 
                This treasure revealed to him in a dream and was in another country
                in a Sacristy with a sycamore tree sprouting out of it
            </p>
        </div>
      
    </div>
  )
}

export default AurthorBio
