import React,{useEffect,useState} from 'react';
// npm install react-toggle-slider
import Switch from "react-switch";


function DarkModeToggle() {

    const [isDarkMode,setIsDarkMode]=useState(false);


    const toggleDarkMode=()=>{
        const html=document.documentElement;

        if(html.classList.contains('dark')){
            html.classList.remove('dark');
            setIsDarkMode(false);
            localStorage.setItem('theme','light');
        }else{
            html.classList.add('dark');
            setIsDarkMode(true);
            localStorage.setItem('theme','dark');
        }
    }

    useEffect(()=>{
        const savedTheme=localStorage.getItem('theme');
        if(savedTheme==='dark'){
            document.documentElement.classList.add('dark');
            setIsDarkMode(true);
        }

    },[])
    

  return (
    //
    <div className='flex gap-4 justify-center items-center mb-10 md:mb-0'>
        <span className="text-xl font-extralight"> {isDarkMode ? 'Dark Mode' : 'Light Mode'}</span>
        <Switch onChange={toggleDarkMode} 
                checked={isDarkMode}
                onColor="#324a85"
                offColor="#a39e9e"
                checkedIcon={false}
                uncheckedIcon={false}
                height={28}
                width={60}/>
           


    </div>
  )
}

export default DarkModeToggle
