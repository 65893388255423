
// import './testStyles.css';
import './App.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import HomePage from "./HomePage";
// import NextBlog from "./NextBlog";
import IndividualBlogPost from "./blog Post/IndividualBlogPost";
import Articles from "./featuredBlogPosts/articles";
import Info from "./contact us/info";
import ScrollToTop from "./ScrollToTop";


function App() {
  return(
    <div className="w-svw max-w-full  overflow-x-hidden overflow-y-hidden">
      <BrowserRouter>
      <Routes>
        <Route path="/"> {/* element={<HomePage/>}> */}
          <Route index element={<HomePage />} />
          <Route path="blogs/:id" element={<IndividualBlogPost/>} />
          <Route path="articles" element={<Articles/>} />
          <Route path="contact" element={<Info/>} />
        
          {/* <Route path="*" element={<NoPage />} />  */}
        </Route>
      </Routes>
      
    </BrowserRouter>
    </div>
  
        

 
  );
}




export default App;







  // <>
  //   <form onSubmit={handleSubmit} className="new-item-form">
  //     <div className='form-row'>
  //       <label htmlFor='item'>New Item</label>
  //       <input type="text" id="item" value={newItem} onChange={e=>setNewItem(e.target.value)}/>
       
  //     </div>
  //     <button className='btn'>Add</button>
  //   </form>

  //   <h1 className='header'>Todo List</h1>
  //   <ul className='list'>
  //     {todos.map(todo=>{
  //       return (
  //       <li key={todo.id}>
  //         <label>
  //           <input type='checkbox' checked={todo.completed}/>
  //           {todo.title}
  //         </label>
  //         <button className='btn btn-danger'>Delete</button>
  //       </li>)
  //     })}
    
  //     </ul>
//   const [newItem,setNewItem]=useState('');
// const [todos,setTodos]=useState([]);
// function handleSubmit(e){
//   e.preventDefault();

//   setTodos((currentTodos)=>{
//     return [...currentTodos,
//       { id:crypto.randomUUID(), title:newItem, completed:false},
//     ]
//   })

//HOW TO RENDER EACH BLOG POST 
//each post will have an id number
//id number is passed to path={}
//Let path={} take in a variable 
//loop through objects to bet the details of the blog