import { useState } from "react";
// import './assets/Home.css';
import { Button } from "@mui/material";
import searchButton from "./assets/search.png"
import PageDisplay from "./pageDisplay";
import SearchIcon from '@mui/icons-material/Search';

const Home = () => {
    const [searchValue,setSearchValue]=useState('');
   
    return ( 
        <div className="md:flex flex-row  items-center justify-between px-4 py-2 shadow-lg space-y-4 lg:space-y-0 grid grid-cols-1 ">
            
           
            <PageDisplay display='HOME' shadowClass="shadow-none" /> 
           
{/* 
            <div className="flex items-center space-x-2 pt-10  ml-10 ">
                <input type="text" className="h-10 w-full lg:w-48 rounded-full hover:bg-slate-50 border border-gray-950 hover:border-orange-700 px-4  -ml-7" value={searchValue} placeholder="SEARCH" onChange={()=>{
                    setSearchValue(searchValue);
                }}
                />
                <Button className="lg:ml-2 w-1/4 lg:w-auto">
                    <SearchIcon/>
                </Button>
            </div> */}
        </div>
     );
}
 
export default Home;