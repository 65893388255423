// import more from './assets/Capture.JPG';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { fontSize } from '@mui/system';
import { Link,Outlet } from 'react-router-dom';

const ViewAllButton = () => {
    return ( 
        <div className="flex justify-end">
                <span className="view-all flex items-center space-x-2 text-2xl font-thin">
                    view all 
                    
                        <Link
                            smooth
                            to='articles'>
                            <NavigateNextIcon style={{fontSize:'40'}}/>
                        </Link>

                    
                </span>
            </div>
     );
}
 
export default ViewAllButton;