import { Outlet, Link } from "react-router-dom";
import {HashLink} from 'react-router-hash-link';
import {useLocation} from 'react-router-dom';
import React,{useEffect,useState} from 'react';
import axios from "axios";
import logoPath from './assets/auxo-blog-2.png';

import ClearIcon from '@mui/icons-material/Clear';
import MenuIcon from '@mui/icons-material/Menu';


// import DarkModeToggle from "./darkModeToggle";


const NavBar = () => {
    const [category,setCategory]=useState([]);

    const [navOpen,setNavOpen] = useState(false);

    const toggleNavBar=()=>{
        setNavOpen(!navOpen);
    }

    //this helps us to get the current url path
    const location=useLocation();


    useEffect(()=>{
        axios.get('https://auxo-ortus-backend.onrender.com/api/categories/')
        .then(response=>{
            const categories=response.data.map(post=>post.category);
            setCategory(categories);
        })
        .catch(error=>{
            // alert(error.message);
            console.log(error.message)
        });

    },[])

    return (
        <div className="dark:bg-gray-900 text-black dark:text-white w-full ">
            <nav className="container mx-auto px-4 py-4 flex items-center justify-between">
                <div className="flex-shrink-0">
                <img src={logoPath} alt="auxo-ortus logo" className=" w-24 md:w-36 sm:ml-0 md:-ml-5"/>
                </div>

              
                

                <ul className="md:flex flex-shrink-0 space-x-4 text-sm md:space-x-6 md:text-base lg:space-x-8 lg:text-lg ml-auto hidden" >
                    <li className="font-light relative group">
                        <Link to="/" className="hover:font-mono hover:text-orange-500">
                            HOME
                        </Link>
                        <div className="absolute w-full h-0.5 bg-orange-400 scale-x-0 group-hover:scale-x-100 transition-transform"/>
                    </li>

                    <li className=" font-light relative group">
                        <Link to="/articles" className="hover:font-mono hover:text-orange-500">
                            ARTICLES
                        </Link>
                        <div className="absolute w-full h-0.5 bg-orange-400 scale-x-0 group-hover:scale-x-100 transition-transform"/>
                    </li>


                    <div className="relative group">
                        <li className="relative group font-light"><Link to="" className="hover:font-mono hover:text-orange-500">CATEGORIES</Link></li>
                        <div className="absolute w-full h-0.5 bg-orange-400 scale-x-0 group-hover:scale-x-100 transition-transform"/>
                        <div className="absolute hidden group-hover:block bg-orange-100 rounded-md shadow-lg ml-2 z-50 p-2">
                            {/* loop through all categories and return a <Link></Link> tag of tha category */}
                            {category.map((category)=>{
                                return ( 
                                    <HashLink 
                                        smooth
                                        className="block p-2 hover:text-orange-500  dark:hover:text-orange-500 hover:font-mono lowercase dark:text-black" 
                                        to={location.pathname === '/articles' ? `#${category}`: `/articles#${category}`}>
                                            <span className="capitalize">{category}</span>
                                        </HashLink>
                                )
                            })}
                        </div>
                    </div>

                    <li className="relative group font-light ">
                        <Link to="/contact" className="hover:font-mono hover:text-orange-500">
                            INFO
                        </Link>
                        <div className="absolute w-full h-0.5 bg-orange-400 scale-x-0 group-hover:scale-x-100 transition-transform"/>
                    </li>
                    
                </ul>
                
                <div className="md:hidden relative group" >
                    <button onClick={toggleNavBar} group>{navOpen?<ClearIcon/>:<MenuIcon/>}</button>
                    <div></div>
                    {navOpen && (
                <div className=" dark:text-black absolute right-2 left-3 basis-full flex flex-col  items-end mr-20   rounded-lg p-2 z-20">
                    <ul className="bg-orange-100 p-2 rounded-md" >
                    <li className="font-light ">
                        <Link to="/" className=" hover:font-mono hover:text-red-800 group">
                            HOME
                        </Link>
                        <div className="absolute w-full h-0.5 bg-orange-400 scale-x-0 hover:scale-x-100 transition-transform"/>
                    </li>

                    <li className="hover:bg-gray-100 font-light mt-5 ">
                    <div className="absolute w-full h-0.5 bg-orange-400 scale-x-0 hover:scale-x-100 transition-transform"/>
                        <Link to="/articles" className="hover:text-red-800 hover:font-mono">
                            ARTICLES
                        </Link>
                        
                    </li>


                    

                    <li className=" font-light mt-5">
                        <Link to="/contact" className="hover:font-mono hover:text-orange-500">
                            INFO
                        </Link>
                        <div className="absolute w-full h-0.5 bg-orange-400 scale-x-0 hover:scale-x-100 transition-transform"/>
                    </li>

                    {/* <li className=" font-light mt-5">
                        <Link to="/blogs/1" className="hover:font-mono hover:text-orange-500">
                            INFO
                        </Link>
                        <div className="absolute w-full h-0.5 bg-orange-400 scale-x-0 hover:scale-x-100 transition-transform"/>
                    </li> */}
                    </ul>
                </div>
            )}
                </div>
                
            </nav>

           
            <Outlet />
        </div> 
     );
}
 
export default NavBar ;

// style={{
//     color:"white",
//     backgroundColor:'pink',
//     borderRadius:'8px',
// }}

//<meta name="viewport" content="initial-scale=1, width=device-width" />
/* <ul className="links" >
                    <li className='hideOnMobile'><Link to="/">HOME</Link></li>
                    <li className='hideOnMobile'><Link to="/blogs">ARTICLES</Link></li>
                    <li className='hideOnMobile'><a href="/">INFO</a></li>
                    <li className='menu-btn' onClick={()=>showSideBar()}><a href="/"><img src={navbar} height='26' width='26' alt='open sidebar'/></a></li>
                </ul> */

                // function showSideBar(){
                //     const sidebar = document.querySelector('.sidebar');
                //     sidebar.style.display='flex'
                // }

                // function hideSidebar(){
                //     const sidebar = document.querySelector('.sidebar');
                //     sidebar.style.display='none'
                // }
                {/* <li onClick={()=>hideSidebar()} style={{position:'top',display:'fixed',top:0,right:0}}><a href='/'><img src={close} alt='close sidebar' height='26' width='26'/></a></li> */}