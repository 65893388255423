const BlogPostInfo = (props) => {
    return ( 
        <div className=" flex flex-row gap-4 mt-20 p-5">
            <div>
                <span className="lg:text-lg text-base font-thin hover:text-orange-400 uppercase">{props.author}</span>
            </div> {/* Place prop here to display the name of author from model */}
            <div class="w-1 bg-gradient-to-b from-red-300 to-orange-400 border-x-orange-400 h-7"></div>

            <div>
                <span className="lg:text-lg text-base font-thin hover:text-orange-400 uppercase">{props.category}</span>
            </div>  {/* Place prop here to display the category of post from model */}
            <div class="w-1 bg-gradient-to-b from-red-300 to-orange-400 border-x-orange-400 h-7"></div>

            <div>
                <span className="lg:text-lg text-base font-thin hover:text-orange-400 uppercase">{props.time}</span>
            </div>   {/* Place prop here to display the time the post was made from model */}

            </div>
     );
}
 
export default BlogPostInfo;