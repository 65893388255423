import blogPost from '../assets/blogPost.jpg';

const   FeaturedPost = (props) => {
    return (  
        <div className='flex flex-col p-10 justify-center items-center rounded-lg hover:bg-orange-200 h-full '>
            <div className=" ">
                <img src={`https://auxo-ortus-backend.onrender.com${props.image}`} className='hover:shadow-xl h-48 w-fit rounded-sm' alt='BlogPost Image'/>
            </div>
            <span className='text-sm text-center font-light md:text-2xl uppercase'>{props.topic}</span>

            <div className=' flex flex-row md:grid md:grid-cols-2 gap-5'>
                <span className=' text-sm md:text-xl font-thin  '>{props.author}</span>
                <span className='text-sm md:text-xl font-thin '>{props.category}</span>
            </div>

            <text className='text-xl font-thin '>{props.snippet}</text>
        </div>
    );
}
 
export default FeaturedPost;