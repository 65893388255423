import NavBar from "../NavBar";
import PageDisplay from "../pageDisplay";
import Footer from "../blog Post/footer";
import ScrollToTop from "../ScrollToTop";
import Enquiries from "./enquiries";
import SocialMediaSharing from "../blog Post/socialMediaSharing";

const Info = () => {
    return ( 
        <div className="dark:bg-gray-900 text-black dark:text-white">
            <ScrollToTop/>
            <NavBar/>
            <PageDisplay display="CONTACT US"/>
            <div className="mt-10 p-10 justify-center items-center">
            <Enquiries/>
            </div>
            <SocialMediaSharing blog='blog'/>
            <Footer/>
        </div>
     );
}
 
export default Info;