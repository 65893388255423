import React,{useState,useEffect} from 'react';
import axios from 'axios';


function Enquiries() {
    const [isSubmitted,setIsSubmitted] =useState(false)

    const [firstName,setFirstName]=useState('')
    const [lastName,setLastName]=useState('')
    const [email,setEmail]=useState('')
    const [message,setMessage]=useState('')

    const handleSubmit=(e)=>{
        e.preventDefault();

        const contactDetails={
            'name':firstName+' '+lastName,
            'email':email,
            'message':message,
        }

        axios.post('https://auxo-ortus-backend.onrender.com/contact_form/contact_info/',contactDetails)
        .then(
            setFirstName(''),
            setLastName(''),
            setEmail(''),
            setMessage(''),
            // alert('CONTACT FORM SUBMITTED SUCCESSFULLY')
            setIsSubmitted(true),
        ).catch(err=>
            // alert(err.message)
            console.log(err.message)
        )

    }
  return (
    isSubmitted?
        <div className='dark:text-white text-black animate-pulse hover:text-orange-400'>
            <span className='font-extralight text-3xl'>YOUR ENQUIRY HAS BEEN SUBMITTED SUCCESSFULLY. SOMEONE WILL REACH OUT TO YOU SOON.</span>
        </div>
        :
        <div>
        
            <span className="font-extralight text-3xl ">For further enquiries about axuo ortus or anything affiliated with us, please fill the form below.
                <div className=' animate-bounce'>
                    Thanks!!
                 </div>
            </span>
       

        <form onSubmit={handleSubmit} className='flex flex-col gap-10'>
            <div className='grid grid-row-3 gap-5 justify-between lg:grid-cols-3 items-center'>
                <div className='flex flex-col text-xl font-extralight '>
                    <label className='hover:text-orange-400'>First Name<span className="text-red-500 font-bold">*</span></label>
                    <input required type="text" value={firstName} onChange={(e)=>setFirstName(e.target.value)} placeholder='Enter name' className='border-2 border-gray-600 rounded-md hover:border-orange-300 dark:text-black lg:w-96 w-full'/>
                </div>  

                <div className='flex flex-col text-xl font-extralight '>
                    <label className='hover:text-orange-400'>Last Name<span className="text-red-500 font-bold">*</span></label>
                    <input required type="text" value={lastName} onChange={(e)=>setLastName(e.target.value)} placeholder='Enter name' className='border-2 border-gray-600 rounded-md hover:border-orange-300 dark:text-black lg:w-96 w-full'/>
                </div>  

                <div className='flex flex-col text-xl font-extralight justify-center '>
                    <label className='hover:text-orange-400'>Email<span className="text-red-500 font-bold">*</span></label>
                    <input required type="email" value={email} onChange={(e)=>setEmail(e.target.value)} placeholder='contact@email.com' className='border-2 border-gray-600 rounded-md hover:border-orange-300 dark:text-black lg:w-96 w-full'/>
                </div> 
            </div>

                <div className='flex flex-col text-xl font-extralight  justify-center'>
                    <label className='hover:text-orange-400'>Message<span className="text-red-500 font-bold">*</span></label>
                    <input required type="text" value={message} onChange={(e)=>setMessage(e.target.value)} placeholder='Enter message here' className='border-2 border-gray-600 rounded-md hover:border-orange-300 dark:text-black h-48 '/>
                </div> 

                <button type='submit' className='hover:text-orange-400 text-xl font-extralight'>Submit</button>           
            
        </form>
    </div>
        
      
    
  )
}

export default Enquiries
