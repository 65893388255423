import { Outlet, Link } from "react-router-dom";
import GitHubIcon from '@mui/icons-material/GitHub';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInOutlinedIcon from '@mui/icons-material/LinkedIn';
import ShareIcon from '@mui/icons-material/Share';


import {useLocation} from 'react-router-dom';
import React,{useEffect,useState} from 'react';
import {HashLink} from 'react-router-hash-link';
import axios from "axios";


import { 
    FacebookShareButton, 
    TwitterShareButton, 
    LinkedinShareButton, 
    WhatsappShareButton 
} from 'react-share';
import DarkModeToggle from "../darkModeToggle";


const Footer = () => {
    const windowURL=window.location.href;
    const shareMessage=`Check out this new post!!`

    const [category,setCategory]=useState([]);

    //this helps us to get the current url path
    const location=useLocation();


    useEffect(()=>{
        axios.get('https://auxo-ortus-backend.onrender.com/api/categories/')
        .then(response=>{
            const categories=response.data.map(post=>post.category);
            setCategory(categories);
        })
        .catch(error=>{
            // alert(error.message);
            console.log(error.message)
        });

    },[])

    return ( 
        <div className="flex flex-col lg:flex-row mb-0 justify-between mt-20 lg:mt-60 gap-10 lg:gap-0">
            <div className="flex flex-col items-center lg:items-start shadow-lg ">
                <span className="text-3xl lg:text-5xl mb-5 font-thin">FOLLOW AUXO ORTUS!!</span>
                <div className="grid grid-cols-4 gap-3 mb-10">
                    
                        <a href="https://www.linkedin.com/in/selina-kudadze">
                            <div className="grid grid-row-2">
                                <LinkedInOutlinedIcon sx={{ fontSize: 50 }} className="ml-4"/>
                                <span className="hover:font-mono hover:text-orange-500 text-sm lg:text-lg font-extralight ml-2">LINKEDIN</span>
                            </div>
                        </a>
                   
                    
                    <div className="grid grid-row-2">
                        <InstagramIcon sx={{ fontSize: 50 }} className="ml-4"/>
                        <span className="hover:font-mono hover:text-orange-500  text-sm lg:text-lg font-extralight">INSTAGRAM</span>
                    </div>
                    
                    <div className="grid grid-row-2">
                        <YouTubeIcon sx={{ fontSize: 50 }} className="ml-2"/>
                        <span className="hover:font-mono hover:text-orange-500 text-sm lg:text-lg font-extralight">YOUTUBE</span>
                    </div>
                    
                    <a href="https://github.com/selinakudadze">
                        <div className="grid grid-row-2">
                            <GitHubIcon sx={{ fontSize: 50 }}/>
                            <span className="hover:font-mono hover:text-orange-500 text-sm lg:text-lg  font-extralight">GITHUB</span>
                        </div>
                    </a>
                    
                </div>

                <div className="flex flex-col">
                    {/* <div className="ml-2">
                        <CallIcon sx={{ fontSize: 35 }}/>
                        <span className="hover:font-mono hover:text-orange-500 text-lg font-extralight ml-2">+233 272 000 691</span>
                    </div> */}
                    
                    <div className="ml-2">
                        <EmailIcon sx={{ fontSize: 35 }}/>
                        <a href="" className="ml-2"><span className="hover:font-mono hover:text-orange-500 text-lg font-extralight">auxo-ortus@yahoo.com</span></a>
                    </div>
                    
                </div>
        </div>


      
          <div className="flex-col items-center justify-center ml-0 md:ml-5 shadow-lg lg:mr-50">
            <div className="flex justify-center ">
                <span  className=" hover:text-orange-500 text-2xl font-extralight ">CATEGORIES</span>
            </div>
                           
                            <div className="flex flex-col lg:grid grid-cols-3  lg:pt-0 gap-10  flex-wrap items-center justify-center">
                            {category.map((category)=>{
                                return ( 
                                    <HashLink 
                                        smooth
                                        className="p-2 text-lg font-thin hover:text-orange-500 hover:font-mono lowercase" 
                                        to={location.pathname === '/articles' ? `#${category}`: `/articles#${category}`}>
                                            <span className="font-thin text-xl uppercase">{category}</span>
                                        </HashLink>
                                )
                            })}
                            </div>
                        </div>


    
        <div className="flex  justify-center lg:justify-end lg:mr-40 mb-10">
            <ul className="grid grid-cols-1 gap-5 mt-10 lg:mt-20">
                <li><Link to="/" className="hover:font-mono hover:text-orange-500 text-2xl font-extralight">HOME</Link></li>
                <li><Link to="/articles" className="hover:font-mono hover:text-orange-500 text-2xl font-extralight">ARTICLES</Link></li>
                <li><Link to="/contact" className="hover:font-mono hover:text-orange-500 text-2xl font-extralight">INFO</Link></li> 
            </ul>
        </div>              
                      
                    

                
          

        <Outlet />
        <DarkModeToggle/>
        
        </div>
     );
}
 
export default Footer;