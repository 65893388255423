import PostSnippet from "./post-Snippet";
import PostSnippetImage from "./post-Snippet-Image";

const PostSection = (props) => {
    return ( 
        <div className=" mb-8 text-justify flex flex-col items-center">
            <p className="text-xl md:text-2xl font-light px-4 md:px-14">{props.sectionText}</p>
            {/* if the section has a snippet/snippet Image show  */}
            <PostSnippet snippet="This is a test snippet" sectionID={props.section_id} />
            <PostSnippetImage sectionID={props.section_id}/>
        </div>
     );
}
 
export default PostSection;